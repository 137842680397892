<template>
  <div>
    <v-sheet :dark="theme === 'dark'" class="py-4 py-lg-16">
      <v-container fluid class="px-0 py-0">
        <v-row no-gutters>
          <v-col cols="0" lg="2"> </v-col>
          <v-col cols="12" lg="6">
            <h2 class="name hidden-lg-and-up px-8">{{ discussionGroup.name }}</h2>
            <v-carousel
              :show-arrows="discussionGroup.coverImages && discussionGroup.coverImages.length > 1"
              :hide-delimiters="
                discussionGroup.coverImages && discussionGroup.coverImages.length <= 1
              "
            >
              <v-carousel-item
                v-for="(item, index) in discussionGroup.coverImages"
                :key="index"
                :src="item"
              />
            </v-carousel>
          </v-col>
          <v-col cols="12" lg="3" class="description__container pl-lg-16">
            <h2 class="name hidden-md-and-down">{{ discussionGroup.name }}</h2>
            <h2 class="date px-8 px-lg-0">
              {{ $d(Date.parse(discussionGroup.startTime), 'long') }}
            </h2>
            <p class="description pt-2 px-8 px-lg-0">
              <read-more
                :less="$t('globals.readLess')"
                :more="$t('globals.readMore')"
                :text="discussionGroup.description"
                v-if="discussionGroup.description"
              />
            </p>
            <div class="d-flex flex-column align-center">
              <toggle-agenda
                class="mb-4"
                :is-on-user-agenda="isOnUserAgenda"
                :is-sending="isSending"
                @add-to-agenda="addToAgenda"
                @remove-from-agenda="removeFromAgenda"
              />

              <v-btn
                v-if="canAccess"
                outlined
                tile
                x-large
                color="primary"
                class="item__control-item"
                @click.native="goToDiscussionGroup"
                width="275px"
                >{{ $t('discussion-group.join') }}
              </v-btn>

              <v-btn
                v-if="!isStarted"
                x-large
                outlined
                tile
                color="primary"
                disabled
                class="item__control-item"
                :class="{ 'mt-4': canAccess }"
                width="275px"
              >
                {{
                  $t('discussion-group.opens', {
                    date: $d(Date.parse(discussionGroup.startTime), 'long'),
                  })
                }}
              </v-btn>

              <v-btn
                v-if="isClosed"
                x-large
                outlined
                tile
                color="primary"
                disabled
                class="item__control-item"
                :class="{ 'mt-4': canAccess }"
                width="275px"
                >{{ $t('discussion-group.closed') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="0" lg="1" />
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import DateUtil from '@/helpers/date/date.helper';
import ReadMore from '@/components/read-more/ReadMore.vue';
import ToggleAgenda from '../agenda/ToggleAgenda.vue';

export default {
  name: 'DiscussionGroupDetail',
  props: ['theme', 'discussionGroup', 'event', 'now', 'isAdmin', 'isOnUserAgenda', 'isSending'],
  data: () => ({
    localIsSending: false,
  }),
  components: {
    ReadMore,
    ToggleAgenda,
  },
  computed: {
    canAccess() {
      return DateUtil.canAccessDiscussionGroup(this.discussionGroup, this.isAdmin, this.now);
    },
    isStarted() {
      return DateUtil.isNowOrBefore(this.discussionGroup.startTime, this.now);
    },
    isClosed() {
      return DateUtil.isNowOrBefore(this.discussionGroup.endTime, this.now);
    },
  },
  methods: {
    addToAgenda() {
      this.$emit('add-to-agenda', this.discussionGroup);
    },
    removeFromAgenda() {
      this.$emit('remove-from-agenda', this.discussionGroup);
    },
    goToDiscussionGroup() {
      this.$emit('enter-discussion-group', this.discussionGroup);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.description {
  white-space: pre-line;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@include carousel-fix();
</style>
